var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "button",
      {
        class: _vm.buttonType,
        attrs: { disabled: _vm.disabled },
        on: {
          click: function($event) {
            return _vm.callback($event)
          }
        }
      },
      [_vm._t("default")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }