<template>
  <div>
    <button :class="buttonType" :disabled="disabled" @click="callback($event)">
      <slot></slot>
    </button>
  </div>
</template>

<script>
export default {
  name: "Button",
  props: {
    buttonType: {
      type: String,
      default: "primary",
    },
    disabled: { type: Boolean, default: false },
  },
  methods: {
    callback: function (e) {
      this.$emit("click", e);
    },
  },
};
</script>
<style lang="scss" scoped>
button {
  padding: 4px 10px 6px 10px;
  font-size: 1rem;
  font-weight: 600;
  outline: 0;
  border-radius: 3px;
  transition: all 0.3s ease 0s;

  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: auto;
  outline: none;
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.6;
  }
  &.grey {
    background-color: #ddd;
    color: #444;
    border-color: #ddd;
    &:hover {
      opacity: 0.8;
    }
  }
  &.white {
    background-color: #fff;
    color: #444;
    border-color: #9ac3ea;
    &:hover {
      opacity: 0.8;
    }
  }
  &.green {
    background-color: #00a65a;
    border-color: #00a65a;
    color: #fff;
    &:hover {
      opacity: 0.8;
    }
  }
  &.primary {
    background-color: #046da2;
    border-color: #046da2;
    color: #fff;
    &:hover {
      opacity: 0.8;
    }
  }
  svg {
    width: 13px;
    margin-right: 5px;
    padding-top: 3px;
    fill: #fff;
  }
}
.modal-footer {
  button {
    margin-left: 0px;
  }
}
*::-ms-backdrop,
button {
  height: 30px;
  align-items: flex-start;
  padding: 8px 10px 6px 10px;
}
*::-ms-backdrop, 
button svg {
  padding-top: 0px;
}
</style>
